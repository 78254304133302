.parent {
    font-weight: bold;
    padding-top: 20px;
    font-size: 16px;
}

.child {
    padding-left: 25px;
    padding-top: 10px;
    cursor: pointer;
    font-size: 14px;
    
}

.child:hover {
  color: #0c5cb2;
}

.pi{
    padding-right: 5px;
}