.p-tabview-nav {
    display: flex;
    justify-content: center;
}

.p-tabview-nav .p-tabview-nav-item {
    margin-right: 1rem;
  }

.card {
    height: 100%;
    width: 100%;
}