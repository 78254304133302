.row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.dateLabel{
    margin-right: 10px;
    margin-top: 10px;
}
