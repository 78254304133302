.tabs {
  display: flex;
  justify-content: space-around;
  background-color: #f0f0f0;
  padding: 10px;
}

.tab {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.tab:hover {
  background-color: #e0e0e0;
}

.customer-list {
  list-style: none;
  padding: 0;
}

.customer-item {
  border: 1px solid #ddd;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
}

.customer-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.add-customer-btn {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-customer-btn:hover {
  background-color: #45a049;
}

.p-dialog {
  width: 500px;
}

.p-dialog .p-button {
  margin-right: 0.5rem;
  margin-top: 0.5rem;
}

.Table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.TableData {
    text-align: center;
    width: 90%;
}

.TableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.p-dialog .p-inputtext {
  width: 100%;
  margin-bottom: 0.5rem;
}

.p-dialog-header {
  background-color: #0078d4;
  color: white;
}

.p-dialog-content {
  padding: 1rem;
}

.p-dialog-footer {
  justify-content: flex-end;
  padding: 1rem;
  background-color: #f0f0f0;
}

.p-dialog-content a:hover {
  text-decoration: underline;
  color: #004578;
}

.p-dialog-header-close-icon {
  color: white;
}