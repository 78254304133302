  .loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .field {
    display: flex;
    flex-direction: column;
  }
  
  .field * {
    font-size: 1rem;
  }
  
  .field .p-inputtext {
    width: 300px;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .loginForm .p-button {
    width: 300px;
    margin-top: 1rem;
  }
  
  .p-field {
    width: 300px;
  }
  